/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import dottedPurpleBackground from '../images/backgrounds/dotted-purple-background-alt.jpg'
import header from '../components/Header'
import footer from '../components/Footer'
import {
  LinkButton,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import NarrowBanner from '../components/NarrowBanner'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Clearlink Insurance Agency | Insurance Quotes | 1-888-269-3848',
          description:
            'Clearlink Insurance Agency | Insurance Quotes | 1-888-269-3848',
          canonical: 'https://clearlinkinsurance.com/birthday-email-lp',
          robots: 'follow,noindex',
        },
        path: '/birthday-email-lp',
        promoCode: '170027',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Clearlink Insurance Agency | Insurance Quotes | 1-888-269-3848',
    defaultRobots: 'follow,noindex',
    main: (
      <>
        <VariableContent
          className="dotted-purple-background"
          style={{
            backgroundImage: `url(${dottedPurpleBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <StaticImage
                src="../images/illustrations/birthday-cake.svg"
                alt="star ratings"
                layout="fixed"
                width={112}
                height={88}
                style={{ marginBottom: '32px' }}
              />
              <Typography variant="h1">Happy Birthday to you!</Typography>
              <Typography variant="h5">
                Instructions: This is an online puzzle you can assemble that is
                similar to a regular jigsaw puzzle. Simply drag the pieces to
                fit together and they will make a clicking sound and lock in
                place when you have placed each piece correctly. Use the menu
                settings to modify the number of puzzle pieces, background
                color, and more. Have fun!
              </Typography>
            </>
          }
        ></VariableContent>

        <div className="jigsaw-puzzle">
          <iframe
            style={{
              width: '100%',
              height: '600px',
              maxHeight: '90vh',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: '#888',
            }}
            allowFullScreen="true"
            src="https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?frm=1&url=aHR0cHM6Ly9yZXMuY2xvdWRpbmFyeS5jb20vY2xlYXJsaW5rL2ltYWdlL3VwbG9hZC92MTcwNzE1MTQ3My9JbnN1cmFuY2UvY2xlYXJsaW5raW5zdXJhbmNlL2VtYWlsL0NsZWFybGlua19JbnN1cmFuY2VfQmlydGhkYXlfUHV6emxlX3VzOHJhaS5qcGc~&nop=250&color=charcoal"
            title="Jigsaw Puzzle"
          >
            Jigsaw Puzzle
          </iframe>
        </div>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                Call Now to Speak to an Agent{' '}
                <span className="display-inline-mobile">{rotatedNumber}</span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}
export default Home
